<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="10" lg="9">
        <v-row justify="center">
          <v-col v-if="selected_sportart || selected_verein" cols="auto">
            <v-btn icon @click="back_to_selection()"><font-awesome-icon icon="fa-light fa-angle-left" /></v-btn>
          </v-col>
          <v-divider v-if="selected_sportart || selected_verein" vertical></v-divider>
          <v-col>
            <h2 class="font-weight-light text-uppercase">
                <v-icon class="mr-2">mdi-magnify</v-icon>Entdecken
            </h2>
          </v-col>
          <v-col cols="auto" align-self="center">
            <v-btn icon color="primary" @click="filter.dialog = true">
              <font-awesome-icon icon="fa-solid fa-filters" size="xl" />
            </v-btn>
            <v-dialog
              v-model="filter.dialog"
              persistent
              overlay-opacity="0.85"
              content-class="rounded-xl"
            >
              <v-card class="rounded-xl">
                <v-toolbar flat>
                  <v-toolbar-title>Filter</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="filter.dialog = false">
                    <font-awesome-icon icon="fa-light fa-xmark" size="xl" />
                  </v-btn>
                </v-toolbar>
                <v-row justify="center">
                  <v-col cols="auto" class="text-center">
                    <v-switch color="primary" v-model="filter.alter" label="Alle Altersklassen anzeigen"></v-switch>
                  </v-col>
                </v-row>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12" class="pa-0" v-if="selected_verein">
            <v-card class="text-center" :class="$vuetify.breakpoint.smAndDown ? 'rounded-0' : 'rounded-xl'">
              <v-img
                  height="150"
                  width="120%"
                  :aspect-ratio="900/150"
                  :src="selected_verein.fotos && selected_verein.fotos.length > 0 ? selected_verein.fotos[0].img : ''"
              >
                  <v-overlay
                      absolute
                      :color="selected_verein.farben.primary"
                      opacity="1"
                      class="text-center"
                      :style="`color: ${selected_verein.farben.text ? selected_verein.farben.text.hex : selected_verein.farben.secondary};`"
                    >
                      <v-avatar tile size="80">
                        <v-img
                          contain
                          :src="selected_verein.logo ? selected_verein.logo : undefined"
                        ></v-img>
                      </v-avatar>
                      <h2>{{ selected_verein.name }}</h2>
                  </v-overlay>
              </v-img>
              <v-card-subtitle>
                <v-row justify="center" class="pb-4" no-gutters>
                  <v-chip class="ma-1" v-for="(item, index) in vereinsportarten" :key="'vereinsportart'+index">
                    <font-awesome-icon v-if="item.iconset == 'Fontawesome'" :icon="item.icon" size="xl" class="mr-2" />
                    <v-icon v-if="item.iconset == 'Material' || !item.iconset" class="mr-2">{{ item.icon }}</v-icon>
                    {{item.name}}
                  </v-chip>
                </v-row>
                <p v-if="selected_verein.beschreibung">
                {{selected_verein.beschreibung}}
                </p>
                <v-btn v-if="selected_verein.website" large :color="selected_verein.farben.primary" :style="`color: ${selected_verein.farben.text ? selected_verein.farben.text.hex : selected_verein.farben.secondary};`" rounded :href="selected_verein.website" target="_blank">
                  <font-awesome-icon icon="fa-duotone fa-browser" size="lg" class="mr-2" />Website des Vereins
                </v-btn>
              </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="!selected_sportart && !selected_verein">
            <v-tabs v-model="tab" fixed-tabs>
              <v-tab>Sportarten</v-tab>
              <v-tab>Vereine</v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-tabs-items v-model="tab" style="background: rgba(0,0,0,0); padding-bottom: 100px;" class="px-2">
          <v-tab-item>
            <v-row justify="center">
              <v-col cols="12" md="10" lg="9" v-if="!selected_sportart">
                <v-row justify="center">
                  <v-col cols="12" md="6" v-for="item in filtered_sportarten" :key="item.id">
                    <v-card color="primary" dark class="rounded-xl text-center" @click="select_sportart(item)">
                      <v-img
                          height="150"
                          width="120%"
                          :aspect-ratio="900/150"
                          :src="item.fotos && item.fotos.length > 0 ? item.fotos[0].img : ''"
                      >
                          <v-overlay
                              absolute
                              color="secondary"
                              opacity="0.7"
                              class="text-center"
                            >
                              <font-awesome-icon v-if="item.iconset == 'Fontawesome'" :icon="item.icon" size="2x" class="mr-1" :class="item.fotos && item.fotos.length > 0 ? 'white--text' : ''" />
                              <v-icon :color="item.fotos && item.fotos.length > 0 ? 'white' : ''" v-if="item.iconset == 'Material' || !item.iconset">{{ item ? item.icon : '' }}</v-icon>
                              <h2 :class="item.fotos && item.fotos.length > 0 ? 'white--text' : ''">{{ item.name }}</h2>
                          </v-overlay>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10" lg="9" v-if="selected_sportart">
                <v-row justify="center">
                  <v-col cols="12" v-if="angebote.length == 0" align-self="center" class="mt-5">
                    <h2 class="text-center">
                      Es wurden leider keine Angebote für die Sportart {{selected_sportart.name}} gefunden.
                    </h2>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    v-for="(item, index) in angebote"
                    :key="index"
                  >
                    <sportangebotcard :item="item"></sportangebotcard>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row justify="center">
              <v-col cols="12" md="10" lg="9" v-if="!selected_verein">
                <v-row justify="center">
                  <v-col cols="12" md="6" v-for="item in filtered_vereine" :key="item.id">
                    <v-card :color="item.farben.primary" dark class="rounded-xl text-center" @click="select_verein(item)">
                      <v-img
                          height="150"
                          width="120%"
                          :aspect-ratio="900/150"
                          :src="item.fotos && item.fotos.length > 0 ? item.fotos[0].img : ''"
                      >
                          <v-overlay
                              absolute
                              :color="item.farben.primary"
                              opacity="0.7"
                              class="text-center"
                              :style="`color: ${item.farben.secondary};`"
                            >
                              <v-avatar tile size="80">
                                <v-img
                                  contain
                                  :src="item.logo ? item.logo : undefined"
                                ></v-img>
                              </v-avatar>
                              <h2>{{ item.name }}</h2>
                          </v-overlay>
                      </v-img>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10" lg="9" v-if="selected_verein">
                <v-row justify="center">
                  <v-col cols="12" v-if="vereinsangebote.length == 0" align-self="center" class="mt-5 text-center">
                    <h2 class="text-center my-4">
                      Der Verein bietet derzeit keine Angebote in deiner Altersklasse an.
                    </h2>
                    <v-btn large @click="back_to_selection()" rounded :color="selected_verein.farben.primary" :style="`color: ${selected_verein.farben.text ? selected_verein.farben.text.hex : selected_verein.farben.secondary};`">
                      <font-awesome-icon icon="fa-light fa-arrow-left" class="mr-2" size="lg" />Zurück
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    v-for="(item, index) in vereinsangebote"
                    :key="index"
                  >
                    <sportangebotcard :item="item"></sportangebotcard>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Card from '../../../components/Sportangebot/card.vue'

export default {
  name: 'Entdecken',
  data() {
    return {
      tab: 0,
      selected_offer: { active: false },
      selected_sportart: '',
      selected_verein: '',
      filter: {
        dialog: false,
        alter: false
      }
    }
  },
  components: {
    "sportangebotcard": Card,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',
    }),
    angebote(){
      return this.sportangebote.all.filter(item => item.sportart?.name == this.selected_sportart.name && item.active && (this.filter.alter ? true : item.altersgruppen.includes(this.user.data.alter.toString())))
    },
    vereinsangebote(){
      return this.sportangebote.all.filter(item => item.verein?.id == this.selected_verein.id && item.active && (this.filter.alter ? true : item.altersgruppen.includes(this.user.data.alter.toString())) )
    },
    vereinsportarten(){
      if(this.selected_verein){
        var sportarten = []
        this.sportarten.all.forEach(item => {
          if(this.vereinsangebote.filter(a => a.sportart.name == item.name).length > 0){
            sportarten.push(item)
          }
        })
        return sportarten
      }
      return []
    },
    filtered_sportarten(){
      var sportarten = []
      this.sportarten.all.forEach(item => {
        if(this.sportangebote.all.filter(s => s.sportart.name == item.name).length > 0){
          sportarten.push(item)
        }
      })
      return sportarten.sort((a,b) => a.name < b.name)
    },
    filtered_vereine(){
      var vereine = []
      this.vereine.all.forEach(item => {
        if(this.sportangebote.all.filter(s => s.verein.id == item.id).length > 0){
          vereine.push(item)
        }
      })
      return vereine.sort((a,b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
    }
  },
  methods: {
    verein(id) {
      return this.vereine.all.find((verein) => verein.id == id)
    },
    sportart(name) {
      return this.sportarten.all.find((art) => art.name == name)
    },
    parseDateTime(obj) {
      var datum = obj.datum.split('-')
      var uhrzeit = obj.uhrzeit.split(':')
      return (
        datum[2] +
        '.' +
        datum[1] +
        '.' +
        datum[0] +
        ' um ' +
        uhrzeit[0] +
        ':' +
        uhrzeit[1] +
        ' Uhr'
      )
    },
    parseTime(obj) {
      var uhrzeit = obj.uhrzeit.split(':')
      return uhrzeit[0] + ':' + uhrzeit[1] + ' Uhr'
    },
    parseDate(obj) {
      var datum = obj.datum.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    select_sportart(item){
      this.selected_sportart = item
      window.scrollTo(0, 0)
    },
    select_verein(item){
      this.selected_verein = item
      window.scrollTo(0, 0)
    },
    back_to_selection(){
      this.selected_sportart = ''
      this.selected_verein = ''
      window.scrollTo(0, 0)
    }
  },
}
</script>